$twilight-blue: #0d4b6b;
$marine: #073e59;
$squash: #f5a623;
$dark_grey: #333333;
$mask: #aaaaaa;
$selection_flooring: rgba(72, 165, 241, 0.1);
$nav_bar_floormap: #f5f4f4;
$darkish-pink: #dc5767;
$pale-blue: #e4f1fd;
$bluish: #297fca;
$dodger-blue: #3fa2f7;
$weird-green: #50e3c2;
$weird-green-hover: #39caa9;
$light-indigo: #6851c9;
$medium-pink: #f5508c;

// Pulte Logo
$pulte-blue: #00334C;

// Grays
$gray-base: #000;
$gray-darker: lighten($gray-base, 13.5%); // #222
$gray-dark: lighten($gray-base, 20%); // #333
$gray: lighten($gray-base, 33.5%); // #555
$gray-light: lighten($gray-base, 46.7%); // #777
$gray-lighter: lighten($gray-base, 93.5%); // #eee

// Brand Colors
$brand-primary: #003359; //Pulte Blue
$brand-success: #5cb85c;
$brand-warning: #f0ad4e;
$brand-danger: #d9534f;
$brand-info: #5C7F92; //selection

// Toast colors
$error-background: #f2dede;
$error-text: #d9534f;

// Choices
$selected-choice-color: lighten($brand-primary, 70%);
$list-group-item-active-bg: darken($gray-lighter, 15%);
